import classNames from "classnames";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { FC, Fragment, useState } from "react";
import Button from "~/components/Button";
import Icon from "~/components/Icon";
import Social from "~/components/Social";
import Text from "~/components/Text";
import Colors from "~/constants/colors";
import { Menus } from "~/constants/menus";
import { Template } from "~/services/template";
import Trans from "~/types/translationKeys";
import { useCookies } from "react-cookie";
import { getQueryParams } from "~/utils/common";
import styles from "./HeaderDrawer.module.scss";
import headerStyles from "../Header.module.scss";
import { motion } from "framer-motion";
import { SolutionsHeader } from "~/constants/data";
import { IconNames } from "~/components/Icon/Icon.type";

interface HeaderDrawerProps {
  open: boolean;
  handleClose: () => void;
  templates: Template[];
}

const subMenuAnimate = {
  enter: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.2,
    },
    display: "flex",
  },
  exit: {
    opacity: 0,
    height: 0,
    transition: {
      duration: 0.2,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const HeaderDrawer: FC<HeaderDrawerProps> = ({
  open,
  handleClose,
  templates,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { utm_source, utm_campaign } = router.query;
  const isBlog = router.asPath.includes("resources/b");
  const [openSubMenu, setOpenSubMenu] = useState("");
  const [cookie, _] = useCookies(["utm_source", "utm_blog", "others"]);

  const onClose = () => {
    handleClose();
    setOpenSubMenu("");
  };

  const renderSolutionsItem = () => {
    const isActive = openSubMenu === "solutions";
    return (
      <div className={styles.menu_item}>
        <div
          className={classNames(styles.label, {
            [styles.active]: isActive,
          })}
          onClick={() => setOpenSubMenu(isActive ? "" : "solutions")}
        >
          {t(Trans.solutions)}
          <Icon
            name={isActive ? "arrow-square-up-2" : "arrow-square-down-2"}
            color={isActive ? Colors.PRIMARY : Colors.GREY_600}
          />
        </div>
        <motion.div
          initial="exit"
          animate={isActive ? "enter" : "exit"}
          variants={subMenuAnimate}
          className={styles.submenu_list}
        >
          {SolutionsHeader.map((item) => (
            <Link
              key={item.title}
              className={styles.submenu_item}
              href={item.url}
              aria-label={item.title}
              onClick={onClose}
              target={item.url?.startsWith("https") ? "_blank" : undefined}
            >
              <div className="flex gap-4">
                <div className={styles.icon} style={{ background: item.color }}>
                  <Icon name={item.icon as IconNames} size={17} color="white" />
                </div>
                <div className="flex flex-col flex-1 gap-1">
                  <Text type="title-3" color={Colors.GREY_800}>
                    {t(item.title)}
                  </Text>
                  <Text type="body-3" color={Colors.GREY_500}>
                    {t(item.desc)}
                  </Text>
                </div>
              </div>
            </Link>
          ))}
        </motion.div>
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.mobile_header, "flex flex-col lg:hidden", {
        [styles.open]: open,
      })}
    >
      <div className="flex items-center p-4">
        <div
          className={classNames(headerStyles.hamburger, {
            [headerStyles.active]: open,
          })}
          onClick={onClose}
        >
          <div className={headerStyles.line} />
          <div className={headerStyles.line} />
          <div className={headerStyles.line} />
        </div>
        <Link href={`/#`} className={headerStyles.header_logo}>
          <Image src="/svgs/logo.svg" alt="CX Genie logo" fill />
        </Link>
        <Link
          className="ml-auto"
          type="outline"
          href={{
            pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-in`,
            query: {
              utm_source: isBlog
                ? `${cookie["utm_source"] || "blog"}`
                : `${cookie["utm_source"] || utm_source || ""}`,
              utm_campaign,
              utm_blog: isBlog ? cookie["utm_blog"] : "",
              others: cookie["others"] || getQueryParams(router.asPath),
            },
          }}
          target="_blank"
        >
          <Button type="outline">{t(Trans.sign_in)}</Button>
        </Link>
      </div>
      <div className="flex-1 px-4 overflow-y-auto">
        <ul className="flex flex-col">
          {renderSolutionsItem()}
          {Menus.map((menu, index) => {
            const isActive = openSubMenu === menu.text;

            return (
              <div key={index} className={styles.menu_item}>
                {menu.submenu ? (
                  <div
                    className={classNames(styles.label, {
                      [styles.active]: isActive,
                    })}
                    onClick={() => setOpenSubMenu(isActive ? "" : menu.text)}
                  >
                    {t(menu.text)}
                    <Icon
                      name={
                        isActive ? "arrow-square-up-2" : "arrow-square-down-2"
                      }
                      color={isActive ? Colors.PRIMARY : Colors.GREY_600}
                    />
                  </div>
                ) : (
                  <Link
                    className={styles.label}
                    href={menu.url}
                    aria-label={menu.text}
                    onClick={onClose}
                  >
                    {t(menu.text)}
                  </Link>
                )}
                {menu.submenu && isActive ? (
                  <motion.div
                    initial="exit"
                    animate={isActive ? "enter" : "exit"}
                    variants={subMenuAnimate}
                    className={styles.submenu_list}
                  >
                    {menu.submenu.map((submenu, index) => (
                      <Link
                        key={index}
                        className={styles.submenu_item}
                        href={submenu.url}
                        aria-label={submenu.text}
                        onClick={onClose}
                        target={
                          submenu.url?.startsWith("https")
                            ? "_blank"
                            : undefined
                        }
                      >
                        {t(submenu.text)}
                      </Link>
                    ))}
                    {menu.key === "tools"
                      ? templates.map((template, index) => (
                          <Link
                            key={index}
                            className={styles.submenu_item}
                            href={`https://cxgenie.ai/templates/${template.id}`}
                            aria-label={template.name}
                            onClick={onClose}
                            target="_blank"
                          >
                            {template.name}
                          </Link>
                        ))
                      : null}
                  </motion.div>
                ) : null}
              </div>
            );
          })}
        </ul>
      </div>

      <div className="flex flex-col gap-5 px-4 py-2">
        <div className="flex h-10 gap-4">
          <Button
            className="flex-1"
            type="outline"
            variant={"secondary"}
            onClick={() => {
              (window as any)?.demo_conversion?.();
              window.open(
                "https://calendar.app.google/9YB8GKe3ZgNt1z3z7",
                "_blank"
              );
            }}
          >
            {t(Trans.book_demo)}
          </Button>
          <Link
            className="flex-1"
            type="outline"
            href={{
              pathname: `${process.env.NEXT_PUBLIC_DASHBOARD_URL}/sign-up`,
              query: {
                utm_source: isBlog
                  ? `${cookie["utm_source"] || "blog"}`
                  : `${cookie["utm_source"] || utm_source || ""}`,
                utm_campaign,
                utm_blog: isBlog ? cookie["utm_blog"] : "",
                others: cookie["others"] || getQueryParams(router.asPath),
              },
            }}
            target="_blank"
          >
            <Button className="w-full">{t(Trans.start_for_free)}</Button>
          </Link>
        </div>
        <div className="flex items-center justify-between gap-2">
          <Social color={Colors.PRIMARY} />
          {/* <DropdownLanguage /> */}
        </div>

        <div className="py-3 border-t-[#D6DAE1] border-t">
          <Text color={Colors.GREY_200}>
            {t(Trans["2024_cx_genie_all_rights_reserved"])}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default HeaderDrawer;
