const getDiscordLink = (text: string) => {
  return `<a href="https://discord.gg/bTs9AvZrFv" target="_blank">${text}</a>`;
};

export const DISCORD_BANNERS = [
  {
    id: "unlock_documenttation",
    content: [
      "<strong>📖 Unlock Complete Documentation</strong>",
      `${getDiscordLink(
        "Join Discord"
      )} for access to comprehensive CX Genie documentation and detailed features.`,
    ],
    image: "/svgs/discord_small_white_logo_blue_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_white_logo_blue_outline.svg",
  },
  {
    id: "free_add_on",
    content: [
      "<strong>🎁 Exclusive Free Add-Ons</strong>",
      `${getDiscordLink(
        "Join our Discord"
      )} community and get access to free CX Genie add-ons. Don't miss out!`,
    ],
    image: "/svgs/discord_small_white_logo_blue_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
  },
  {
    id: "need_help",
    content: [
      "<strong>🙋‍♂️ Need Help? Get Instant Support!</strong>",
      `${getDiscordLink(
        "Join our Discord"
      )} for real-time support from our team and community. Your questions answered fast!`,
    ],
    image: "/svgs/discord_small_blue_logo_grey_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_blue_logo_grey_outline.svg",
  },
  {
    id: "chat_with_developers",
    content: [
      "<strong>💻 Chat with Our Developers</strong>",
      `${getDiscordLink(
        "Join our Discord"
      )} to talk directly with the devs. Share your feedback and ideas!`,
    ],
    image: "/svgs/discord_small_blue_logo_blue_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_blue_logo_blue_outline.svg",
  },
  {
    id: "report_bug",
    content: [
      "<strong>🐞 Report and Resolve Bugs Fast</strong>",
      `${getDiscordLink(
        "Join our Discord"
      )} to report bugs and get them fixed quickly. Help us improve CX Genie!`,
    ],
    image: "/svgs/discord_small_white_logo_blue_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
  },
  {
    id: "join_conversation",
    content: [
      "<strong>💬 Join the Conversation</strong>",
      `Be part of our engaged ${getDiscordLink(
        "Discord community"
      )}. Share insights, tips, and tricks with fellow users!`,
    ],
    image: "/svgs/discord_small_white_logo_grey_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
  },
  {
    id: "stay_in_loop",
    content: [
      "<strong>📢 Stay in the Loop</strong>",
      `${getDiscordLink(
        "Join Discord"
      )} for exclusive announcements and updates. Be the first to know!`,
    ],
    image: "/svgs/discord_small_white_logo_white_outline.svg",
    mobileImage: "/svgs/discord_mobile_small_white_logo_white_outline.svg",
  },
];
