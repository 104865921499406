const Colors = {
  PRIMARY: "#364DE7",
  PRIMARY_100: "#CDD8F6",
  GREY_00: "#FFFFFF",
  GREY_50: "#F2F3F5",
  GREY_100: "#D6DAE1",
  GREY_200: "#C4C9D2",
  GREY_300: "#A3A9B3",
  GREY_400: "#7D828B",
  GREY_500: "#5C6169",
  GREY_600: "#36393F",
  GREY_700: "#2C2E33",
  GREY_800: "#202225",
  GREY_900: "#17181A",
  ACCENT_YELLOW: "#FFB130",
  ACCENT_LIGHT_YELLOW: "#FFEFD6",
  ACCENT_ORANGE: "#FC8B23",
  ACCENT_LIGHT_ORANGE: "#FEE4CD",
  ACCENT_GREEN: "#3BA55C",
  ACCENT_LIGHT_GREEN: "#D8EDDE",
  ACCENT_BLUE: "#3E70DD",
  ACCENT_LIGHT_BLUE: "#D8E2F8",
  ACCENT_VIOLET: "#702DFF",
  ACCENT_LIGHT_VIOLET: "#DCCCFF",
  ACCENT_RED: "#ED4245",
  ACCENT_LIGHT_RED: "#FBD9DA",
  ACCENT_PINK: "#ff73b6",
  ACCENT_SILVER: "#747F8D",
  ACCENT_LIGHT_SILVER: "#E3E5E8",
};

export default Colors;
