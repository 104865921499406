import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { motion } from "framer-motion";
import styles from "./MenuItem.module.scss";
import classNames from "classnames";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import Icon from "../Icon";

interface SubmenuItem {
  text: string;
  url: string;
}

interface MenuItemProps {
  text?: string;
  submenuClassName?: string;
  renderSubmenu?: (closeSubmenu: () => void) => ReactNode;
  submenu?: SubmenuItem[];
  url?: string;
  isDarkMode?: boolean;
}

const subMenuAnimate = {
  enter: {
    opacity: 1,
    y: "100%",
    x: 0,
    transition: {
      duration: 0.2,
    },
    display: "block",
  },
  exit: {
    opacity: 0,
    y: "100%",
    x: 30,
    transition: {
      duration: 0.2,
      delay: 0.2,
    },
    transitionEnd: {
      display: "none",
    },
  },
};

const MenuItem: FC<MenuItemProps> = ({
  text,
  submenuClassName,
  renderSubmenu,
  submenu,
  url,
  isDarkMode,
}) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const isHasSubmenu = useMemo(
    () => !!renderSubmenu || (Array.isArray(submenu) && submenu.length > 0),
    [renderSubmenu, submenu]
  );

  const renderSubmenuContent = useCallback(() => {
    if (!isHasSubmenu) return;
    if (renderSubmenu) return renderSubmenu(() => setIsHovered(false));
    return (
      <ul className={styles.submenu_list}>
        {submenu?.map((submenuItem) => (
          <Link
            key={submenuItem.text}
            href={submenuItem.url}
            onClick={() => setIsHovered(false)}
          >
            <li className={styles.submenu_item}>{t(submenuItem.text)}</li>
          </Link>
        ))}
      </ul>
    );
  }, [isHasSubmenu, renderSubmenu, submenu, t]);

  return (
    <motion.li
      className={classNames(styles.menu_item, {
        [styles.dark_mode]: isDarkMode,
      })}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      {!url ? (
        <Fragment>
          <div className="flex items-center">
            {text} {isHasSubmenu && <Icon name="arrow-square-down-2" />}
          </div>
          <motion.div
            initial="exit"
            animate={isHovered ? "enter" : "exit"}
            variants={subMenuAnimate}
            className={classNames(styles.submenu, submenuClassName)}
          >
            {renderSubmenuContent()}
          </motion.div>
        </Fragment>
      ) : (
        <Link
          href={url}
          aria-label={text}
          target={url?.startsWith("https") ? "_blank" : undefined}
        >
          {text}
        </Link>
      )}
    </motion.li>
  );
};

export default MenuItem;
